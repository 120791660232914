import * as React from 'react';
import {useEffect} from 'react';

import {FetchService} from '../../client/fetch/fetch.service';
import {ImpError} from '../../client/imp-error/imp-error.class';
import {ItemToAdd} from '../order-items/order-items.class';
import {JobCostingService} from '../../client/job-costing/job-costing.service';
import {MyShopService} from '../../client/my-shop/my-shop.service';
import {OrderItemsWorkflow} from '../../client/order-items/order-items.workflow';
import {UserAgent, WebMessage} from '../../client/user-agent/user-agent.class';
import {useService} from '../react/ServiceContext';

interface ScannerLogicProps {
    componentName?: string;
    onCloseScanner?: () => void;
    onSubmitObsoleteItems?: () => void;
    onSubmitObsoleteItemsErr?: (submitObsoleteItemsErr: ImpError) => void;
    onSubmitObsoleteItemsRes?: (scanErrorList?: string[]) => void;
    onSubmitScannedLocationsErr?: (submitScannedLocationsErr: ImpError) => void;
    onSubmitScannedLocationsRes?: (scanErrorList?: string[]) => void;
    onUploadOfflineItemsRes?: () => void;
}
export const ScannerLogic = ({
    componentName,
    onCloseScanner,
    onSubmitObsoleteItems,
    onSubmitObsoleteItemsErr,
    onSubmitObsoleteItemsRes,
    onSubmitScannedLocationsErr,
    onSubmitScannedLocationsRes,
    onUploadOfflineItemsRes,
}: ScannerLogicProps) => {
    const fetchService: FetchService = useService(`fetchService`);
    const jobCostingService: JobCostingService = useService(`jobCostingService`);
    const myShopService: MyShopService = new MyShopService(fetchService);
    const orderItemsWorkflow: OrderItemsWorkflow = useService(`orderItemsWorkflow`);

    // register event listeners
    useEffect(() => {
        // Setup listeners for ReactNative app
        if (UserAgent.isReactNative()) {
            window.addEventListener(`message`, (event: MessageEvent) => {
                handleWebMessage(event);
            });
            document.addEventListener(`message`, (event: MessageEvent) => {
                handleWebMessage(event);
            });
        }
        // Only run once per page load
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * Returns message intended for ScannerErrorModal
     * @param errorMessage - Error message to parse
     */
    const getScanErrorMessage = (errorMessage: string) => {
        let message = errorMessage;
        if (errorMessage.includes(`Invalid`)) {
            message = `Invalid part number.`;
        }
        if (errorMessage.includes(`unavailable`)) {
            message = `Item not currently available.`;
        }
        if (errorMessage.includes(`blocked`)) {
            message = `This item is blocked.`;
        }
        return message;
    };

    /**
     * Handles incoming message from the React Native environment
     * @param messageEvent
     */
    const handleWebMessage = (messageEvent: MessageEvent) => {
        // Confirm data is a string
        if (typeof messageEvent.data !== `string`) {
            return;
        }

        // Attempt parse as JSON
        let webMessage: WebMessage;
        try {
            webMessage = JSON.parse(messageEvent.data);
        } catch (parseErr) {
            // Not JSON, do nothing
        }

        // Handle closeScanner
        if (webMessage?.event === `closeScanner`) {
            if (onCloseScanner) {
                onCloseScanner();
            }
            return;
        }

        // Handle scanBarcode
        if (webMessage?.event === `scanBarcode` && webMessage.scannerMode === `repairOrders`) {
            const jobNbr = jobCostingService.currentRepairOrder.jobNbr.toString();
            jobCostingService
                .createRoJobItem(jobNbr, webMessage.itemNum, 1)
                .then(() => {
                    jobCostingService
                        .getRepairOrder(jobNbr)
                        .then(() => {
                            UserAgent.postNativeMessage({
                                event: `scanSuccess`,
                                repairOrder: jobCostingService.currentRepairOrder,
                            });
                        })
                        .catch((getRepairOrderErr: ImpError) => {
                            showError(getRepairOrderErr.message, true);
                        });
                })
                .catch((createRoJobItemErr: ImpError) => {
                    showError(createRoJobItemErr.message, true);
                });
            return;
        }

        // Handle submitObsoleteItems
        if (webMessage?.event === `submitObsoleteItems`) {
            if (onSubmitObsoleteItems) {
                onSubmitObsoleteItems();
            }
            myShopService
                .submitObsoleteItems(webMessage.obsoleteItems)
                .then((submitObsoleteItemsRes) => {
                    if (onSubmitObsoleteItemsRes) {
                        onSubmitObsoleteItemsRes(submitObsoleteItemsRes.errorList);
                    }
                    UserAgent.postNativeMessage({event: `submitObsoleteItemsSuccess`});
                })
                .catch((submitObsoleteItemsErr: ImpError) => {
                    if (onSubmitObsoleteItemsErr) {
                        onSubmitObsoleteItemsErr(submitObsoleteItemsErr);
                    }
                });
            return;
        }

        // Handle submitScannedLocations
        if (webMessage?.event === `submitScannedLocations`) {
            myShopService
                .submitScannedLocations(webMessage.scannedLocations)
                .then((submitScannedLocationsRes) => {
                    if (onSubmitScannedLocationsRes) {
                        onSubmitScannedLocationsRes(submitScannedLocationsRes.errorList);
                    }
                    UserAgent.postNativeMessage({event: `submitScannedLocationsSuccess`});
                })
                .catch((submitScannedLocationsErr: ImpError) => {
                    if (onSubmitScannedLocationsErr) {
                        onSubmitScannedLocationsErr(submitScannedLocationsErr);
                    }
                });
            return;
        }

        // Handle uploadOfflineItems
        if (webMessage?.event === `uploadOfflineItems`) {
            const itemsToAdd: ItemToAdd[] = [];
            webMessage.offlineItems.forEach((item) => {
                itemsToAdd.push({
                    gaEcommerce: {item_list_name: `OfflineMode`},
                    item: item.item,
                    unitsOrdered: parseInt(item.quantity),
                });
            });
            orderItemsWorkflow.addToOrderModalObservable(itemsToAdd, componentName, true, undefined, undefined, true).subscribe({
                next: () => {
                    if (onUploadOfflineItemsRes) {
                        onUploadOfflineItemsRes();
                    }
                },
                error: () => {
                    // Error silently
                },
            });
            return;
        }

        // Handle updateItemQty
        if (webMessage?.event === `updateItemQty` && webMessage.scannerMode === `repairOrders`) {
            updateItemQty(webMessage);
            return;
        }
    };

    /**
     * Sends error message to app scanner
     * @param errorMessage
     * @param isScanError
     */
    const showError = (errorMessage: string, isScanError: boolean) => {
        UserAgent.postNativeMessage({
            event: `errorScanner`,
            message: isScanError ? getScanErrorMessage(errorMessage) : errorMessage,
            title: isScanError ? `Item not added` : `An error has occurred`,
        });
    };

    /**
     * Updates quantity of provided itemNum on job
     * @param webMessage
     */
    const updateItemQty = (webMessage: WebMessage) => {
        if (webMessage.newQty === 0) {
            jobCostingService
                .removeRoJobItem(webMessage.repairOrder.jobNbr.toString(), webMessage.itemNum)
                .then(() => {
                    UserAgent.postNativeMessage({
                        event: `removeItemSuccess`,
                    });
                })
                .catch((removeRoJobItemErr: ImpError) => {
                    showError(removeRoJobItemErr.message, false);
                });
        } else {
            jobCostingService
                .updateRoJobItem(webMessage.repairOrder.jobNbr.toString(), webMessage.itemNum, webMessage.newQty)
                .then(() => {
                    jobCostingService
                        .getRepairOrder(webMessage.repairOrder.jobNbr.toString())
                        .then(() => {
                            UserAgent.postNativeMessage({
                                event: `updateItemQtySuccess`,
                                repairOrder: jobCostingService.currentRepairOrder,
                            });
                        })
                        .catch((getRepairOrderErr: ImpError) => {
                            showError(getRepairOrderErr.message, false);
                        });
                })
                .catch((updateRoJobItemErr: ImpError) => {
                    showError(updateRoJobItemErr.message, false);
                });
        }
    };

    /**
     * Template
     */
    return <></>;
};
